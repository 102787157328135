import React from 'react'
import { Helmet } from 'react-helmet'
import { Box } from '@chakra-ui/react'
import config from '../../../data/SiteConfig'
import './index.css'
import Navbar from '../../components/Navbar/Navbar'
import Footer from '../../components/Footer/Footer'

const BaseLayout = ({ children }) => (
  <Box>
    <Helmet>
      <meta name="title" content={config.siteTitle} />
      <meta name="description" content={config.siteDescription} />
    </Helmet>
    <Navbar />
    <Box p={4}>{children}</Box>
    <Footer />
  </Box>
)

export default BaseLayout
