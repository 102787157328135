import React from 'react'
import { useColorMode, IconButton } from '@chakra-ui/react'
import { SunIcon, MoonIcon } from '@chakra-ui/icons'

const ToggleLight = () => {
  const { colorMode, toggleColorMode } = useColorMode()
  return (
    // <Button onClick={toggleColorMode}>
    //   Toggle {colorMode === 'light' ? 'Dark' : 'Light'}
    // </Button>
    <IconButton
      onClick={toggleColorMode}
      aria-label="Modo oscuro"
      icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
    />
  )
}

export default ToggleLight
