import React from 'react'
import { Helmet } from 'react-helmet'
import urljoin from 'url-join'
import moment from 'moment'
import config from '../../../data/SiteConfig'

function SEO({
  title = config.siteTitle,
  titleSeo = `${config.siteTitle} ${config.siteTitleSep} ${config.siteTitleAlt}`,
  description = config.siteDescription,
  image = config.siteLogo,
  lang = 'es',
  datePublished = moment(new Date()).format(),
  dateModified = moment(new Date()).format(),
  breadcrumbs,
  permalink,
  breadcrumbList,
  noindex,
}) {
  const metaTitle = `${titleSeo} | ${config.siteTitleAlt}`

  const blogURL = urljoin(config.siteUrl, config.pathPrefix)
  const pageUrl = urljoin(blogURL, permalink)
  const getImagePath = imageURI => {
    if (
      !imageURI.match(
        '(https?|ftp|file)://[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]'
      )
    )
      return urljoin(config.siteUrl, config.pathPrefix, imageURI)
    return imageURI
  }

  const imagePath = getImagePath(image)

  const authorJSONLD = {
    '@type': 'Person',
    name: config.userName,
    email: config.userEmail,
    address: config.userLocation,
  }

  const logoJSONLD = {
    '@type': 'ImageObject',
    url: getImagePath(config.siteLogo),
  }

  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: blogURL,
      name: title,
      alternateName: config.siteTitleAlt ? config.siteTitleAlt : '',
    },
  ]

  if (breadcrumbList) {
    // there is a breadcrumbList
    schemaOrgJSONLD.push(breadcrumbList)
  } else if (breadcrumbs && !breadcrumbList) {
    // there are breadcrumbs

    const breadcrumbItemList = [
      {
        '@type': 'ListItem',
        position: 1,
        name: config.siteTitle,
        item: blogURL,
      },
    ]

    let nextItem = 2
    breadcrumbs.forEach(elem => {
      breadcrumbItemList.push({
        '@type': 'ListItem',
        position: nextItem,
        name: elem.name,
        item: blogURL + elem.url,
      })
      nextItem += 1
    })

    breadcrumbItemList.push({
      '@type': 'ListItem',
      position: nextItem,
      name: title,
      item: pageUrl,
      image: imagePath,
    })

    schemaOrgJSONLD.push({
      '@context': 'http://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: breadcrumbItemList,
    })
  } else if (permalink !== '/') {
    // there is neither breadcrumbs or a breadcrumbList, and it's not the homepage
    schemaOrgJSONLD.push({
      '@context': 'http://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          name: config.siteTitle,
          item: blogURL,
        },
        {
          '@type': 'ListItem',
          position: 2,
          name: title,
          item: pageUrl,
          image: imagePath,
        },
      ],
    })
  }

  schemaOrgJSONLD.push({
    '@context': 'http://schema.org',
    '@type': 'Article',
    url: blogURL,
    name: title,
    alternateName: config.siteTitleAlt ? config.siteTitleAlt : '',
    headline: title,
    image: { '@type': 'ImageObject', url: imagePath },
    author: authorJSONLD,
    publisher: {
      ...authorJSONLD,
      '@type': 'Organization',
      logo: logoJSONLD,
    },
    datePublished,
    dateModified,
    description,
  })

  // noindex
  // noindex
  // noindex
  // noindex
  // noindex
  // noindex
  // noindex
  // noindex

  return (
    <Helmet>
      <meta
        name="robots"
        content={noindex ? 'noindex, follow' : 'index, follow'}
      />

      {/* The Content-Language entity-header field describes the natural language(s) of the intended audience for the enclosed entity. Note that this might not be equivalent to all the languages used within the entity-body.  */}
      <meta httpEquiv="content-language" content={lang} />
      {/* If you want to state that a document is written in Spanish then use: */}
      <html lang={lang} />

      {/* General tags */}
      <title>{title}</title>
      <meta name="title" content={metaTitle} />
      <meta name="description" content={description} />
      <meta name="image" content={imagePath} />

      {/* Schema.org tags */}
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>

      {/* OpenGraph tags */}
      <meta property="og:url" content={pageUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imagePath} />
      <meta
        property="fb:app_id"
        content={config.siteFBAppID ? config.siteFBAppID : ''}
      />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:creator"
        content={config.userTwitter ? config.userTwitter : ''}
      />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imagePath} />
    </Helmet>
  )
}

export default SEO
